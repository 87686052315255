import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";

const OurExpertise = () => {

  const expertiseDataQuery = useStaticQuery(graphql`
    query {
      allStrapiProjects(filter: {is_featured: {eq: false}, strapiId: {in: [9,10,11]}}, limit: 3) {
        nodes {
          cover_image_large {
            publicURL
          }
          title
          slug
          short_description
          strapiId
          id
          is_featured
        }
      }
    }
  `);


  const expertiseData = expertiseDataQuery.allStrapiProjects.nodes;
  return (
    <section className="expertiseContainer innerSection">
      <div className="wrapper">
        <div className="outerHeading">Our Expertise</div>
        <h2>Web and Mobile Solutions</h2>
        <p className="subHead">Whether it's a web or mobile app, we can build the solutions you need.</p>
        <div className="expertiseBlkWrap">
          <ul>
            {expertiseData.map((object) => {
              return (
                <li key={object.id}>
                  <div className="picWrap">
                    {object.cover_image_large != null &&
                      <img src={object.cover_image_large.publicURL} alt="" />
                    }

                  </div>
                  <div className="expertiseDetail">
                    <h3>{object.title}</h3>
                    <p>{object.short_description}</p>
                    <Link to={'/our-work/' + object.slug} className="seeDetail">See the work</Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  )
};

export default OurExpertise