import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FeaturedProject from "../components/includes/featuredProjects";
import OurExpertise from "../components/includes/ourExpertiseSection";
import DevelopmentArchitecture from "../components/includes/developmentArchitecture";
import Testimonial from "../components/includes/testimonial";

const metaDescription="Digital Avenues is a team of creators and builders. We develop custom software that is designed to transform your business.";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Web and Mobile Solutions" description={metaDescription} />
      <div className="contentWrapper">
        <section className="staticContainer innerSection">
          <div className="wrapper">
            <h2>Nimble. Savvy. Seasoned.</h2>
            <p className="subHead">Digital Avenues is a team of creators and builders. We develop custom software that is designed to transform your business.</p>
          </div>
        </section>
        <FeaturedProject></FeaturedProject>
        <OurExpertise></OurExpertise>
        <Testimonial />
        <DevelopmentArchitecture></DevelopmentArchitecture>

      </div>
    </Layout>
  )
}


export default IndexPage
