import { useStaticQuery, graphql, Link } from "gatsby";
import React, { useState, useEffect } from "react";

const DevelopmentArchitecture = () => {

    const technologiesQuery = useStaticQuery(graphql`query {
        allStrapiTechnologies(sort: {fields: strapiId, order: ASC}) {
            nodes {
                core_teches {
                    name
                }
                id
                name
                cover_image {
                    publicURL
                }
            }
        }
    }`);
    const [technologies, setTechnologies] = useState([]);
    const [currentTechnology, setCurrentTechnology] = useState(null);

    useEffect(() => {
        makeTheFristTechnologyDefault();
    }, []);
    function makeTheFristTechnologyDefault() {
        const technologyLists = technologiesQuery.allStrapiTechnologies.nodes;
        technologyLists.forEach((element, index) => {
            if (index === 0) {
                element.isActive = true;
            } else {
                element.isActive = false;
            }
        });
        setTechnologies(technologyLists);
        setCurrentTechnology(technologyLists[0]);

    }

    function changeTechnology(index) {
        let allTechnologies = [...technologies];
        allTechnologies.forEach(element => { element.isActive = false; });
        allTechnologies[index].isActive = true;
        setTechnologies(allTechnologies);
        setCurrentTechnology(allTechnologies[index]);
        console.log(allTechnologies[index]);
    }
    return (
        <section className="architectureContainer innerSection">
            <div className="wrapper">
                <h2>Solution Oriented Approach</h2>
                <p className="subHead">With our technology-agnostic approach, we focus on providing solutions that focus on your business goals. All of our work is performed at the highest standards to build scalable and secure solutions.</p>
                <h3>Technologies</h3>
                <div className="techNav">
                    <ul>
                        {
                            technologies.map((technology, index) => {
                                return (
                                    <li onClick={() => changeTechnology(index)} key={'menu_' + technology.id} className={technology.isActive ? 'active' : ''}>
                                        <span>{technology.name}</span>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
                <div className="techDesc">
                    {currentTechnology != null &&
                        <div className="illustrationBlk"><img src={currentTechnology.cover_image.publicURL} alt="Image" /></div>
                    }
                    <ul>
                        {currentTechnology != null &&

                            currentTechnology.core_teches.map((technology, index) => {
                                return (
                                    <li key={index}>{technology.name}</li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
};
export default DevelopmentArchitecture