import { useStaticQuery, graphql, Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";


const sliderSettings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: true,
    centerPadding: "200px",
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: true,
          infinite: true,
          centerMode: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};

const Testimonial = () => {

    const testimonialQuery = useStaticQuery(graphql`query {
        allStrapiTestimonials(sort: {order: DESC, fields: publish_date}) {
            edges {
              node {
                position
                message
                name
                project {
                    id
                    slug
                }
              }
            }
        }
    }`);

    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        //console.log(testimonialQuery);
        const testimonialsData = testimonialQuery.allStrapiTestimonials.edges;
        setTestimonials(testimonialsData);
    }, []);

    function headingClassName(message){
        let _className = 'testiHeading';

        if(message != null){
            if(message.length >= 100){
                _className = _className+' longHeading';
            }
        }
        return _className;
    }
    
    return (
        <section class="testimonialContainer innerSection">
            <Slider {...sliderSettings}>
                {
                    testimonials.map((testimonial, index) => {
                        return(
                            <div key={testimonial.node.message}>
                                <div className={headingClassName(testimonial.node.message)}>{testimonial.node.message}"</div>
                                <div className="testiAuthor">
                                    <div className="authorName">{testimonial.node.name}</div>
                                    <div className="authorDesc">{testimonial.node.position}</div>
                                </div>
                                {testimonial.node.project != null &&
                                    <Link className="seeDetail" to={'/our-work/'+testimonial.node.project.slug}>View Case Study</Link>
                                }
                            </div>
                        )
                    })
                }
                
            </Slider>
        </section>
    );
};
export default Testimonial;