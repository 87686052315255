import {Link,  useStaticQuery, graphql } from "gatsby";
import React, { useState, useEffect } from "react";

const FeaturedProject = () => {

    const featuredProjectListQuery = useStaticQuery(graphql`query {
    allStrapiProjects(limit: 3, filter: { is_featured: { eq: true } }, sort: { fields: feature_rank, order: ASC }) {
        edges {
            node {
                id
                title
                slug
                short_description
                feature_description
                services {
                    name
                }
                project_type {
                    name
                }
                core_tech {
                    name
                }
                feature_image {
                    publicURL
                }
            }
        }
        }
    }`);
    
    const [featuredProjects, setFeaturedProjects] = useState([]);

    useEffect(() => {
        makeTheFristElementFeatured();
    }, []);

    function makeTheFristElementFeatured() {
        const featuredProjectLists = featuredProjectListQuery.allStrapiProjects.edges;
        featuredProjectLists.forEach((element, index) => {
            if (index === 0) {
                element.isActive = true;
            } else {
                element.isActive = false;
            }
        });
        setFeaturedProjects(featuredProjectLists);
    }

    function changeFeaturedProject(index) {
        let currentFeaturedProject = [...featuredProjects];
        currentFeaturedProject.forEach(element => { element.isActive = false; });
        currentFeaturedProject[index].isActive = true;
        setFeaturedProjects(currentFeaturedProject);
    }

    function makeServiceString(serviceArray){
        let serviceString = '';
        if(serviceArray && serviceArray != null && serviceArray != ''){
            let serviceValueArray = [];
            serviceArray.map((service) =>
                serviceValueArray.push(service.name)
            );
            serviceString = serviceValueArray.join(', ');
        }
        return serviceString;
    }

    function makeCoreTechnologyString(coreTechnologyArray){
        let coreTechnologyString = '';
        if(coreTechnologyArray && coreTechnologyArray != null && coreTechnologyArray != ''){
            let coreTechnologyValueArray = [];
            coreTechnologyArray.map((coreTechnology) =>
                coreTechnologyValueArray.push(coreTechnology.name)
            );
            coreTechnologyString = coreTechnologyValueArray.join(', ');
        }
        return coreTechnologyString;
    }

    function projectClassName(projectTypeArray, isActive){
        let temp = [];
        let defaultClass = 'featuredWeb'; 
        projectTypeArray.forEach(element => {
            temp.push(element.name);
        });
        if (temp.includes("Web") && temp.includes("Mobile")) {
            defaultClass = 'featuredWebMobile';
        } 
        else if(temp.includes("Mobile")) {
            defaultClass = 'featuredMobile';
        }
        else{
            defaultClass = 'featuredWeb';
        }

        if(isActive){
            return defaultClass+' active';
        }

        return defaultClass;
    }

    return (
        <section className="featuredContainer innerSection">
            <div className="wrapper">
                <h2>Featured Work</h2>
                <div className="featuredContWrap">
                    <div className="featuredLeft">
                        <ul>
                            {
                                featuredProjects.map((projects, index) => {
                                    /* let projectType = '';
                                    projects.node.project_type.map((projectTypeVal) =>
                                        projectType += projectTypeVal.name + ', '
                                    ); */
                                    return (
                                        <li onClick={() => changeFeaturedProject(index)} className={projects.isActive ? 'active' : ''} key={'featured_menu_' + projects.node.id} id={'featured_menu_id_' + projects.node.id}>
                                            <h3>{projects.node.title}</h3>
                                            <p>{projects.node.feature_description}</p>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>

                    <div className="featuredDesc">
                        <ul>
                            {/* {currentFeaturedProject != null &&

                                <li className={featureProjectClass}>
                                    <div className="descContWrap">
                                        <div className="descCont">
                                            <h4>{currentFeaturedProject.node.title}</h4>
                                            <p>{currentFeaturedProject.node.short_description}</p>
                                            <div className="techBlk">
                                                <p><strong>Services: </strong>
                                                    {
                                                        currentFeaturedProjectSercives.replace(/,\s*$/, "")
                                                    }
                                                </p>
                                                <p><strong>Core Technologies: </strong>{currentFeaturedProjectCoreTechnologies.replace(/,\s*$/, "")}</p>
                                            </div>
                                        </div>
                                        <div className="featuredImg"><img src={currentFeaturedProject.node.feature_image.publicURL} alt="featuredImg" /></div>
                                    </div>
                                    <div className="goDetail"><a className="readMore" href="#">Read Case Study</a></div>
                                </li>
                            } */}

                            {
                                featuredProjects.map((project, index) => {
                                    return(
                                        <li className={projectClassName(project.node.project_type, project.isActive)} key={index}>
                                            <div className="descContWrap">
                                                <div className="descCont">
                                                    <h4>{project.node.title}</h4>
                                                    <p>{project.node.short_description}</p>
                                                    <div className="techBlk">
                                                        <p>
                                                            <strong>Services: </strong>
                                                            { makeServiceString(project.node.services) }
                                                        </p>
                                                        <p>
                                                            <strong>Core Technologies: </strong>
                                                            { makeCoreTechnologyString(project.node.core_tech) }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="featuredImg"><img src={project.node.feature_image.publicURL} alt="featuredImg" /></div>
                                            </div>
                                            <div className="goDetail">
                                                <Link className="readMore" to={'/our-work/'+project.node.slug}>Read Case Study</Link>
                                            </div>
                                        </li>
                                    )
                                    
                                })
                                    
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedProject